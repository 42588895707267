import { LOCALE_US, LOCALE_ES } from './langs'

const translations = Object.freeze({
  [LOCALE_US]: {
    post: {
      default: {
        title: 'The Complete Business eGuide to WhatsApp',
        ctaText: 'Download the free WhatsApp for Business eGuide now',
        description: 'Companies are using the WhatsApp Business API to communicate with their customers on a personal level and increase lifetime value.',
        points: [
          'Discover how to engage customers with WhatsApp',
          'Find out how to access the WhatsApp Business API',
          'Learn best practices for automation and bots on WhatsApp',
        ]
      },
      moreContentFooter: {
        relatedArticles: 'Liked that? Check these out!'
      }
    }
  },
  [LOCALE_ES]: {
    post: {
      default: {
        title: 'Crea relaciones personales a gran escala en WhatsApp.',
        ctaText: 'Para más información',
        description: 'Usa chatbots y automatización en WhatsApp Business API con Hubtype. Infórmate más al respecto y solicítalo ya.',
        points: [],
      },
      moreContentFooter: {
        relatedArticles: 'Otros contenidos seleccionados para ti'
      }
    }
  }
})

const i18n = {
  _iterate(dict, key) {
    const path = key.split('.')
    
    let nested = dict
    for (let i = 0; i < path.length; i++) {
      const step = path[i]
      nested = nested[step]
      if (nested === undefined) {
        console.error(`Could not find the property "${step}" on from key "${key}"`)
        return `{i18n[${locale}]:${key}}`
      } else if (i === path.length - 1 && typeof nested === 'object' && !(nested instanceof Array)) {
        console.error(`Not a valid value on prefix "${step}". Is the key "${key}" correct?`)
        return `{i18n[${locale}]:${key}}`
      } else if (i < path.length - 1 && typeof nested === 'string') {
        console.error(`Invalid key "${key}"`)
        return `{i18n_[${locale}]:${key}}`
      }
    }
    return nested
  },
  getTranslation(locale, key) {
    const translation = this._iterate(translations[locale], key)
    if (typeof translation !== 'string') {
      console.warn(`Not a single value translation, but ${translation.length} values. Use "getTranslationList" instead`)
    }
    return translation
  },
  getTranslationList(locale, key) {
    const translation = this._iterate(translations[locale], key)
    if (typeof translation === 'string') {
      console.warn(`Single value translation, use "getTranslation" instead`)
    }
    return translation
  }
}

export default i18n

import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import {
  WhatsappSocialLink,
  TwitterSocialLink,
  FacebookSocialLink,
  LinkedinSocialLink,
} from '../social/shareLinks'

const padding = 95
const paddingMobile = 40

const SocialLinks = ({ style, text, url }) => (
  <div style={style}>
    <WhatsappSocialLink style={{ marginBottom: 12 }} text={`${text} ${url}`} />
    <TwitterSocialLink
      isSharing={true}
      style={{ marginBottom: 12 }}
      text={text}
      url={url}
    />
    <FacebookSocialLink
      isSharing={true}
      style={{ marginBottom: 12 }}
      text={text}
      url={url}
    />
    <LinkedinSocialLink
      isSharing={true}
      style={{ marginBottom: 12 }}
      text={text}
      url={url}
    />
  </div>
)

export const Body = ({ className, children, shareText, shareUrl }) => {
  useEffect(() => {
    let fig = document.querySelector('figure.wp-block-image:first-of-type')
    let social = document.createElement('div')
    fig.appendChild(social)
    ReactDOM.render(
      <SocialLinks
        style={{ position: 'absolute', paddingTop: 40 }}
        text={shareText}
        url={shareUrl}
      />,
      social
    )
  }, [])
  return (
    <article
      className={className}
      dangerouslySetInnerHTML={{ __html: children }}
    />
  )
}

export const StyledBody = styled(Body)`
  padding-bottom: 100px;
  & > * {
    font-family: Merriweather;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 30px;
    padding: 0px ${padding}px;
    color: #48484d;
    @media (max-width: 512px) {
      padding: 0px ${paddingMobile}px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    color: black;
    margin-bottom: 18px;
  }

  h2 {
    margin-top: 48px;
  }

  h3,
  h4 {
    font-size: 24px;
    line-height: 30px;
  }

  p {
    margin-bottom: 40px;
  }

  figure.wp-block-image:first-of-type {
    padding: 0px;
    position: relative;
    .gatsby-image-wrapper {
      max-width: 400px;
    }
    margin: 48px 0px;
  }

  blockquote {
    border-left: 4px solid #6f16ff;
    width: 75%;
    margin-left: 25%;
    margin-top: 6%;
    margin-bottom: 6%;
    padding: 0px 16px;
    font-style: italic;
    font-size: 19px;
    line-height: 33px;
    color: #838383;
    font-weight: bold;
    @media (max-width: 512px) {
      width: 80%;
      margin-left: 10%;
    }
  }

  a {
    color: #ff2c5d;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`

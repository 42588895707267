import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'

import BlogPostCard from './blogPostCard'
import i18n from '../../translations'

const H2 = styled.h2`
  align-items: flex-start;
  color: #000000;
  font-size: 24px;
  line-height: 32px;  
  `
const getTranslation = (post, lang) =>
  post.polylang_translations.find(
    tr => tr.polylang_current_lang === lang
  )

class MoreContentFooter extends React.PureComponent {
  static propTypes = {
    content: PropTypes.array.isRequired,
    lang: PropTypes.string.isRequired,
    maxWidth: PropTypes.number.isRequired,
    widthRatio: PropTypes.number.isRequired,
  }

  render() {
    const { content, lang, maxWidth, widthRatio } = this.props;

    return (
      <Flex style={{ maxWidth }} flexDirection="column">
        <Box>
        <H2>{i18n.getTranslation(lang, 'post.moreContentFooter.relatedArticles')}</H2>
        </Box>
        <Flex flexDirection={['column', 'row']}>
          {content.map((post, i) => {
            const i18nPost = getTranslation(post, lang)
            return (
              i18nPost && (
                <BlogPostCard
                  key={i}
                  post={i18nPost}
                  lang={lang}
                  width={widthRatio}
                />
              )
            )
          })}
        </Flex>
      </Flex>
    )
  }
}

export default MoreContentFooter;

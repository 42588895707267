import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import Img from 'gatsby-image'

import Arrow from '../static/img/icons/rarrowRose.svg'
import DefaultCTAImage from '../static/img/post/DefaultCTAImage.svg'
import DefaultCTAImageMobile from '../static/img/post/DefaultCTAImageMobile.svg'
import Eguide from '../static/img/post/EguideImage.svg'
import EguideMobile2 from '../static/img/post/EguideImageMobile2.svg'
import Footer from '../components/footer'
import Head from '../components/head'
import MoreContentFooter from '../components/blog/moreContentFooter'
import Navbar from '../components/Navbar'
import i18n from '../translations'

import { CategoryText } from '../components/blog/blogComponents'
import { DesktopFlex, MobileFlex } from '../components/breakpoints'
import { StyledBody } from '../components/post/body'

import '../styles/main.scss'

const Entities = require('html-entities').XmlEntities

const MAX_POSTS_TO_SHOW = 3
const MORE_CONTENT_WIDTH_RATIO = 1 / 3
const SCREEN_MAX_WIDTH = 875
const padding = 90
const paddingMobile = 15

const Title = styled.h1`
  padding: 0px ${padding}px;
  color: #000000;
  margin-bottom: 24px;
  @media (max-width: 512px) {
    padding: 0px ${paddingMobile}px;
  }
`

const PublishDate = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  padding: 0px ${padding}px;
  color: #000000;
  @media (max-width: 512px) {
    padding: 0px ${paddingMobile}px;
  }
`

const StyledCategoryText = styled(CategoryText)`
  padding: 0px ${padding}px;
  margin-top: 4px;
  margin-bottom: 36px;
  @media (max-width: 512px) {
    padding: 0px ${paddingMobile}px;
  }
`
const Description = styled.p`
  font-size: 16px;
  line-height: 23px;
  color: #696973;
`
const Linked = styled.a`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  /* identical to box height */

  color: #ff2b5e;
  text-decoration: none;
`
const Author = styled.p`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  padding-bottom: 50px;
  padding: 20px ${padding}px;
  margin-top: -50px;
  @media (max-width: 512px) {
    padding: 0px 40px;
    padding-bottom: 20px;
  }
  color: #696973;
`

const BlogPost = ({ data, pageContext, props }) => {
  const { wordpressPost: post, latestPosts } = data

  const currentLang = pageContext.lang
  const blogData = {
    comesFrom: 'web',
    slug: data.wordpressPost.slug,
    lang: currentLang,
  }

  const defaultUSURL = post.acf.link
  const defaultESURL = 'https://www.hubtype.com/es/whatsapp-business-api'
  let defaultHref = undefined

  if (currentLang == 'es_ES') {
    defaultHref = defaultESURL
  } else {
    defaultHref = defaultUSURL
      ? defaultUSURL
      : 'https://www.hubtype.com/whatsapp-e-guide/'
  }
  
  const morePosts = latestPosts.edges
    .map(post => post.node)
    .filter(({ polylang_translations: translations }, i) =>
      i < MAX_POSTS_TO_SHOW && translations.filter(({ polylang_current_lang: locales }) => locales.includes(currentLang))
    )

  const sendClick = props => {
    if (typeof window === 'undefined') return
    //window.analytics.identify(blogData)
    window.analytics.track('CTA Blog Bottom', blogData)
  }

  const Clickable = props => {
    return <a href={defaultHref}>{props.children}</a>
  }

  const TextContainer = post => {
    const title = post.title || i18n.getTranslation(currentLang, 'post.default.title')
    const description = post.desc || i18n.getTranslation(currentLang, 'post.default.description')
    const ctaText = post.url_text || i18n.getTranslation(currentLang, 'post.default.ctaText')
    const points = i18n.getTranslationList(currentLang, 'post.default.points') || []

    return (
      <Flex flexDirection="column">
        <h3>{title}</h3>
        {post.desc && (
          <div
            dangerouslySetInnerHTML={{
              __html: post.desc,
            }}
          ></div>
        )}
        {!post.desc && (
          <Description>
            {description}
            {points.length && (
              <ul>
                {points.map((point, i) => (
                  <li style={{ marginTop: 20 }} key={i}>
                    {point}
                  </li>
                ))}
              </ul>
            )}
          </Description>
        )}
        <Linked onClick={sendClick} href={defaultHref}>
          {ctaText} <Arrow style={{ marginLeft: 0 }} />
        </Linked>
      </Flex>
    )
  }

  return (
    <Flex
      flexDirection="column"
      css={{ background: 'white', overflow: 'hidden' }}
    >
      <Head
        alternates={pageContext.i18n}
        autopilot={pageContext.autopilot}
        description={post.yoast_meta.yoast_wpseo_metadesc}
        lang={currentLang}
        title={post.yoast_meta.yoast_wpseo_title}
        uri={pageContext.relativeUrl}
      />
      <Navbar py={[12, 12, 12]} pageContext={pageContext} />
      <Flex
        width={1}
        justifyContent="center"
        pt={136}
        css={{ backgroundColor: 'white' }}
      >
        <Box width={1} style={{ maxWidth: SCREEN_MAX_WIDTH }}>
          <Title>{Entities.decode(post.title)}</Title>

          <PublishDate>
            {post.modified ? `${post.modified} (Updated)` : post.date}
          </PublishDate>
          <StyledCategoryText>
            {post.categories.map(c => c.name).join(', ')}
          </StyledCategoryText>
          <StyledBody shareText={post.title} shareUrl={pageContext.absoluteUrl}>
            {post.content}
          </StyledBody>
          <Author>
            Written by <b>{post.author.name}</b>
          </Author>
          <Box
            pt={10}
            mb={50}
            style={{
              borderTop: '1px solid #DADADA',
              transform: 'skewY(-2deg)',
            }}
          />
          <Flex px={[15, 15]} flexDirection={['column', 'row']} pb={50}>
            <Flex pb={40} mr={70} width={[1, 1 / 2]}>
              <TextContainer
                title={post.acf.title_text}
                desc={post.acf.description_text}
                url_text={post.acf.learn_more_text}
                url={post.acf.link}
                lang={currentLang}
              />
            </Flex>
            <Flex width={[1, 1 / 2]}>
              {post.acf.img ? (
                <Clickable href={post.acf.link} lang={currentLang}>
                  <Img
                    style={{
                      width: 540,
                      height: 270,
                    }}
                    fluid={post.acf.img.localFile.childImageSharp.fluid}
                  />
                </Clickable>
              ) : (
                <>
                  <DesktopFlex>
                    <Clickable href={post.acf.link} lang={currentLang}>
                      {currentLang == 'es_ES' ? (
                        <DefaultCTAImage />
                      ) : (
                        <Eguide />
                      )}
                    </Clickable>
                  </DesktopFlex>
                  <MobileFlex>
                    <Clickable href={post.acf.url} lang={currentLang}>
                      {currentLang == 'es_ES' ? (
                        <DefaultCTAImageMobile />
                      ) : (
                        <EguideMobile2 />
                      )}
                    </Clickable>
                  </MobileFlex>
                </>
              )}
            </Flex>
          </Flex>
          <Box
            pt={10}
            mb={50}
            style={{
              borderTop: '1px solid #DADADA',
              transform: 'skewY(-2deg)',
            }}
          />
        </Box>
      </Flex>
      <Flex
        pt={[0, 55]}
        style={{ alignItems: 'center' }}
        flexDirection="column"
        ml={[15, 0]}
      >
        {morePosts.length > 0 && (
          <MoreContentFooter 
            content={morePosts}
            lang={pageContext.lang}
            maxWidth={SCREEN_MAX_WIDTH}
            widthRatio={MORE_CONTENT_WIDTH_RATIO}
          />
        )}
      </Flex>
      <Footer pageContext={pageContext} />
    </Flex>
  )
}

export default BlogPost

export const pageQuery = graphql`
  fragment PostFields on wordpress__POST {
    id
    slug
    content
    date(formatString: "MMMM DD, YYYY")
    title
  }
  query BlogPostByID($id: String!, $lang: String!) {
    wordpressPost(id: { eq: $id }) {
      id
      title
      slug
      content
      author {
        name
      }
      modified(formatString: "MMMM DD, YYYY")
      date(formatString: "MMMM DD, YYYY")
      acf {
        title_text
        description_text
        learn_more_text
        link
        img {
          localFile {
            childImageSharp {
              fluid(maxWidth: 995, quality: 10) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      categories {
        name
        slug
      }
      tags {
        name
        slug
      }
      author {
        name
        slug
      }
    }
    latestPosts: allWordpressPost(
      filter: { polylang_current_lang: { eq: $lang } }
      sort: { fields: [ date ], order: DESC }
      limit: 6
      skip: 0
    ) {
      edges {
        node {
          id
          polylang_current_lang
          polylang_translations {
            categories {
              name
            }
            date(formatString: "MMMM DD, YYYY")
            featured_media {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1050, quality: 85) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            polylang_current_lang
            slug
            title
            wordpress_id
          }
          id
          slug
          status
          wordpress_id
        }
      }
    }
  }
`
